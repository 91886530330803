import React from 'react';
import Slideshow from '../components/Slideshow'; // Import the Slideshow component


const imageUrls = [
  '/images/chemicals/chem1.jpg',
  '/images/chemicals/chem2.jpg',
];

const Chemicals = () => {
  return (
    <div className="page-container">
      <h1>Detailing Chemicals and Products</h1>
      <p>
        Welcome to the world of high-quality detailing chemicals and products at Garcia's Auto Detailing. We provide a range of top-notch products that are essential for maintaining the beauty and longevity of your vehicles and equipment.
      </p>
      <h2>Gallery</h2>
      <Slideshow imageUrls={imageUrls} /> {/* Add the Slideshow component here */}

      {/* Include a gallery component here showcasing before-and-after photos */}
      <h2>Our Products</h2>
      <p>
        Explore our selection of detailing chemicals and products designed to meet your specific needs:
      </p>
      <ul>
        <li>
          <strong>Car Care Products:</strong> Our car care products include premium waxes, polishes, sealants, and cleaning solutions to keep your vehicles looking pristine.
        </li>
        <br />
        <li>
          <strong>Boat and Marine Products:</strong> We offer specialized marine detailing products to protect and enhance the appearance of your boats and watercraft.
        </li>
        <br />
        <li>
          <strong>Motorcycle Enthusiast Line:</strong> Designed with motorcycle enthusiasts in mind, our product line includes motorcycle cleaners, chrome polishes, and leather conditioners.
        </li>
        <br />
        <li>
          <strong>Aircraft Detailing Supplies:</strong> Find aviation-approved detailing supplies for maintaining the exterior and interior of your aircraft.
        </li>
        <br />
        <li>
          <strong>General Detailing Supplies:</strong> Our general detailing supplies range from microfiber towels and applicators to brushes and interior care products.
        </li>
      </ul>

      <h2>Why Choose Our Products</h2>
      <ul>
        <li>
          <strong>Quality Assurance:</strong> Our products are sourced from trusted manufacturers known for their quality and performance.
        </li>
        <br />
        <li>
          <strong>Expert Recommendations:</strong> Our team can provide expert recommendations to help you choose the right products for your specific detailing needs.
        </li>
        <br />
        <li>
          <strong>Convenience:</strong> You can conveniently purchase our detailing products online or visit our physical store.
        </li>
        <br />
        <li>
          <strong>Customer Satisfaction:</strong> We are committed to ensuring your satisfaction with our products and offer excellent customer support.
        </li>
      </ul>
    </div>
  );
};

export default Chemicals;
