import React from 'react';
import ContactForm from './ContactForm'; // Update the path accordingly
import '../css/Footer.css'; // Import the Footer CSS file
import '../css/Contactform.css'; // Import the Contactform css

const Footer = () => {
  // const address = "1650 TX-35 Loop, Alvin, TX 77511, USA"; // Replace this with your actual address

  // const openMaps = () => {
  //   const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
  //   window.open(url, '_blank'); // Open the URL in a new tab/window
  // };

  return (
    <footer>
      <div className="footer-content">
        <ContactForm /> {/* Include the ContactForm component here */}
        <br /><br /><br />
        {/* <div>1650 TX-35 Loop, Alvin, TX 77511, USA
          <br />
          <button onClick={openMaps} style={{ backgroundColor: "black" }}>
            <span style={{ backgroundColor: "black", color: "white" }}>Come check us out</span>
          </button>
        </div> */}
        <img src="/images/main/m1.jpg" alt="logo" style={{width:"50vw"}} />
        <br /><br />
        <div className="copyright">
          &copy; {new Date().getFullYear()} <a className="wd" href="https://webdev-international.com" target="_blank" rel="noopener noreferrer">webdev-international.com</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
