import React from 'react';
import Slideshow from '../components/Slideshow'; // Import the Slideshow component

// Sample image URLs (replace with your own)
const imageUrls = [
  '/images/aircrafts/a1.jpg',
  '/images/aircrafts/a2.jpg',
  '/images/aircrafts/a3.jpg',
];

const Aircrafts = () => {
  return (
    <div className="page-container">
      <h1>Aircraft Detailing Services</h1>
      <p>
      Garcia's Auto Detailing offers meticulous aircraft detailing services to ensure your planes and helicopters maintain their appearance and functionality. Our team of experts understands the unique requirements of aircraft detailing and is dedicated to delivering top-quality results.
      </p>
      <h2>Gallery</h2>
      <Slideshow imageUrls={imageUrls} /> {/* Add the Slideshow component here */}

      {/* Include a gallery component here showcasing before-and-after photos */}
      <h2>Our Services</h2>
      <p>
        Our comprehensive aircraft detailing services include:
      </p>
      <ul>
        <li>
          <strong>Exterior Detailing:</strong> We provide a thorough cleaning and protection service for your aircraft's exterior, ensuring it remains free from dirt, contaminants, and environmental damage.
        </li>
        <br />
        <li>
          <strong>Interior Detailing:</strong> Our interior detailing covers cleaning and sanitization of the cabin, upholstery, and cockpit, creating a pristine and comfortable environment for passengers and crew.
        </li>
        <br />
        <li>
          <strong>Avionics Cleaning:</strong> We specialize in cleaning and maintaining avionics equipment to ensure they function optimally and safely.
        </li>
        <br />
        <li>
          <strong>Detailing Packages:</strong> Choose from our range of packages designed to cater to your aircraft's unique needs, whether it's a small private plane or a commercial helicopter.
        </li>
      </ul>

      <h2>Why Choose Us</h2>
      <ul>
        <li>
          <strong>Aircraft Detailing Experts:</strong> Our team comprises experienced professionals with expertise in aircraft detailing, ensuring the highest level of care for your aircraft.
        </li>
        <br />
        <li>
          <strong>Quality and Safety:</strong> We adhere to industry standards to provide top-quality results and maintain the safety and integrity of your aircraft.
        </li>
        <br />
        <li>
          <strong>Convenience:</strong> We offer flexible scheduling and can work on your aircraft at your hangar or location, minimizing downtime.
        </li>
        <br />
        <li>
          <strong>Customer Satisfaction:</strong> Your satisfaction is our priority, and we are committed to delivering services that exceed your expectations.
        </li>
      </ul>
    </div>
  );
};

export default Aircrafts;
