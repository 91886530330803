import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../css/Navbar.css';

const Navbar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [lastClicked, setLastClicked] = useState(null);

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLinkClick = (link) => {
    setLastClicked(link);
    setDropdownOpen(false); // Close the dropdown when a link is clicked
    setMenuOpen(false); // Close the menu in mobile view
  };

  const handleDropdownHover = () => {
    if (!menuOpen) {
      setDropdownOpen(true); // Open the dropdown when hovered, if the menu is closed
    }
  };

  return (
    <nav className="navbar">
      <div className="menu-icon" onClick={handleMenuToggle}>
        &#9776; {/* This is the hamburger icon */}
      </div>
      <ul className={`nav-links ${menuOpen ? 'show' : ''}`}>
        <li className='nav-link-item'><Link to="/home" onClick={() => handleLinkClick("/home")} className={lastClicked === "/home" ? 'last-clicked' : ''}>Home</Link></li>
        <li className='nav-link-item'><Link to="/chemicals" onClick={() => handleLinkClick("/chemicals")} className={lastClicked === "/chemicals" ? 'last-clicked' : ''}>Chemicals</Link></li>
        <li
          className={`nav-link-item dropdown ${dropdownOpen ? 'show' : ''}`}
          onClick={handleDropdownToggle}
          onMouseEnter={handleDropdownHover}
          onMouseLeave={() => setDropdownOpen(false)} // Close dropdown when mouse leaves
        >
          <div className="dropdown-toggle">
            Transportation
            <ul className={`dropdown-content ${dropdownOpen ? 'show' : ''}`}>
              <li className="nav-link-item"><Link to="/cars-trucks" onClick={() => handleLinkClick("/cars-trucks")} className={lastClicked === "/cars-trucks" ? 'last-clicked' : ''}>Cars/Trucks</Link></li>
              <li className="nav-link-item"><Link to="/outdoor-vehicles" onClick={() => handleLinkClick("/outdoor-vehicles")} className={lastClicked === "/outdoor-vehicles" ? 'last-clicked' : ''}>Outdoor Vehicles</Link></li>
              <li className="nav-link-item"><Link to="/motorcycles" onClick={() => handleLinkClick("/motorcycles")} className={lastClicked === "/motorcycles" ? 'last-clicked' : ''}>Motorcycles</Link></li>
              <li className="nav-link-item"><Link to="/aircrafts" onClick={() => handleLinkClick("/aircrafts")} className={lastClicked === "/aircrafts" ? 'last-clicked' : ''}>Aircrafts</Link></li>
            </ul>
          </div>
        </li>
        <li className='nav-link-item'><Link to='/training' onClick={() => handleLinkClick("/training")} className={lastClicked === "/training" ? 'last-clicked' : ''}>Training</Link></li>
        <li className='nav-link-item'><Link to='/locations' onClick={() => handleLinkClick("/locations")} className={lastClicked === "/locations" ? 'last-clicked' : ''}>Locations</Link></li>
      </ul>
    </nav>
  );
};

export default Navbar;
