import React from 'react';

function ContactForm() {
  return (
    <div>
      <p>
        For quotes, questions, or would like to get in touch, please reach us at{' '}
        {/* <a href="mailto:cooksta120021@gmail.com">cooksta120021@gmail.com</a>. */}
       { <a href='https://www.facebook.com/Garciasautodetailandwash'><br />Garcias Auto Detailing</a>}
      </p>
    </div>
  );
}

export default ContactForm;
