import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import CarsTrucks from './templates/CarsTrucks';
import Boats from './templates/Boats';
import Motorcycles from './templates/Motorcycles';
import Aircrafts from './templates/Aircrafts';
import Chemicals from './templates/Chemicals';
import Training from './templates/Training';
import Locations from './templates/Locations';
import Announcements from './templates/Announcements';
import Base from './templates/Base';
import Footer from './components/Footer';
import Header from './components/Header';
import './css/Main.css';
// const baseUrl ="http://garciasautodetailing.com"
function App() {
  return (
    <Router basename='/'>
      <div className="App" style={{ textAlign: 'center' }}>
        <Header />
        <Navbar />
        <Routes>
          <Route path='/' element={<Base />} />
          <Route path='/home' element={<Base />} ></Route>
          <Route path="/cars-trucks" element={<CarsTrucks />} />
          <Route path="/outdoor-vehicles" element={<Boats />} />
          <Route path="/motorcycles" element={<Motorcycles />} />
          <Route path="/aircrafts" element={<Aircrafts />} />
          <Route path="/chemicals" element={<Chemicals />} />
          <Route path='/training' element={<Training />} />
          <Route path='/locations' element={<Locations />}/>
          <Route path='/announcements' element={<Announcements />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
