import React from 'react';
import '../css/Base.css';
import Slideshow from '../components/Slideshow'; // Import the Slideshow component

const imageUrls = [
    '/images/training/1.jpg',
    '/images/training/2.jpg',
    '/images/training/3.jpg',
  ];
function Base() {
  return (
    <div className="page-container">
      <h1>Experience Unmatched Quality with Garcia's Auto Detailing!</h1>
      <p>
        With over 25 years of expertise in the industry, Garcia's Auto Detailing
        is your go-to for premier detailing services for cars, boats,
        motorcycles, planes, SUVs, and more. Our professional team ensures your
        vehicle shines like new, both inside and out. Plus, we offer
        top-of-the-line ceramic coating to provide long-lasting protection and
        a stunning finish.
      </p>
      <p>Discover the difference experience makes with Garcia's Auto Detailing!</p>
      {/* <h2>Meet the Team</h2>
      <div className="team-members">
        <div className="team-member">
          <img src="team_member1.jpg" alt="Team Member 1" />
          <h3>John Doe</h3>
          <p>Detailing Specialist</p>
        </div>
        <div className="team-member">
          <img src="team_member2.jpg" alt="Team Member 2" />
          <h3>Jane Smith</h3>
          <p>Ceramic Coating Expert</p>
        </div>
      </div> */}
      <Slideshow imageUrls={imageUrls} /> {/* Add the Slideshow component here */}
    </div>
  );
}

export default Base;
