import React, { useState, useEffect, useCallback } from 'react';
import '../css/Slideshow.css'; // Import the CSS file for the Slideshow component

const Slideshow = ({ imageUrls }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const showSlide = useCallback((n) => {
    setCurrentSlide((prevSlide) => (prevSlide + n + imageUrls.length) % imageUrls.length);
  }, [imageUrls]);

  useEffect(() => {
    const interval = setInterval(() => {
      showSlide(1);
    }, 4000);

    return () => {
      clearInterval(interval);
    };
  }, [showSlide]);

  return (
    <div className="slideshow-container">
      <div className="prev" onClick={() => showSlide(-1)}>&#10094;</div>
      <div className="next" onClick={() => showSlide(1)}>&#10095;</div>
      <div className="slides">
        {imageUrls.map((imageUrl, index) => (
          <div key={index} className={`slide ${currentSlide === index ? 'active' : ''}`}>
            <img src={imageUrl} alt={`Car ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Slideshow;
