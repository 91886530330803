import React, { useState, useEffect } from 'react';
import '../css/Announcements.css';

const Announcements = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 666);
    };

    // Initial check for screen width on component mount
    setIsSmallScreen(window.innerWidth <= 666);

    // Listen for window resize events
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const smallScreenMessage = (
    <div className="small-screen-message" style={{ backgroundColor: 'black' }}>
      <h1>Please rotate your screen to view the content.</h1>
    </div>
  );

  const iframeElement = (
    <iframe
      title='Garcias_Auto_Detailing'
      src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FGarciasautodetailandwash&tabs=timeline&width=500&height=499&small_header=false&adapt_container_width=true&hide_cover=true&show_facepile=false&appId"
      style={{ border: 'none', overflow: 'hidden', width: '100%', height: '500px' }}
      scrolling="no"
      frameBorder="0"
      allowFullScreen={true}
      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
    ></iframe>
  );

  return (
    <div className="fb-page-container">
      <div style={{ margin: '0 auto' }}>{isSmallScreen ? smallScreenMessage : iframeElement}</div>
    </div>
  );
};

export default Announcements;
