import React from "react";

const Locations = () => {
    const address1 = "1650 TX-35 Loop, Alvin, TX 77511, USA"; // Replace this with your actual address
    const address2 = "1524 E Broadway St Pearland, TX 77581"; // Replace this with your actual address

    const openMaps1 = () => {
        const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address1)}`;
        window.open(url, '_blank'); // Open the URL in a new tab/window
    };

    const openMaps2 = () => {
        const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address2)}`;
        window.open(url, '_blank'); // Open the URL in a new tab/window
    };

    return (
        <div className="page-container">
            <h1>Our Current Locations</h1>
            <ul>
                <li>
                    <h2>1524 E Broadway St Pearland, TX 77581</h2>
                    <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!4v1717580407904!6m8!1m7!1syRSuiLlZDH2RTCkZrg5eBA!2m2!1d29.54697605186094!2d-95.23149505406737!3f181.20496241928166!4f-10.978795388103435!5f0.7820865974627469"
                            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                            title="Pearland location"
                        ></iframe>
                    </div>
                    <br />
                    <button onClick={openMaps2}>Come check us out</button>
                </li>
                <li>
                    <h2>1650 S Bypass 35 Alvin, TX 77511</h2>
                    <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}>
                    <iframe 
                    src="https://www.google.com/maps/embed?pb=!4v1718073483115!6m8!1m7!1sh2FZZ8hirGS_OLYzvbUubw!2m2!1d29.40906788034001!2d-95.23459038392215!3f59.444039265376446!4f0.2870822494267742!5f0.7820865974627469" 
                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: 0 }}
                    allowfullscreen="" 
                    loading="lazy" 
                    referrerpolicy="no-referrer-when-downgrade"
                    title="Alvin Location">
                    </iframe>
                    </div>
                    <br />
                    <button onClick={openMaps1}>Come check us out</button>
                </li>
                <li>
                    <div style={{ backgroundColor: "red", textAlign: 'center' }}>
                        <h2>And 46 locations to be announced</h2>
                    </div>
                </li>
            </ul>
        </div>
    );
};

export default Locations;
