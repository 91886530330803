import React from 'react';
import Slideshow from '../components/Slideshow'; // Import the Slideshow component

// Sample image URLs (replace with your own)
const imageUrls = [
  '/images/motorcycles/m1.jpg',
  '/images/motorcycles/m2.jpg',
  '/images/motorcycles/m3.jpg',
];
const Motorcycles = () => {
  return (
    <div className="page-container">
      <h1>Motorcycle Detailing Services</h1>
      <p>
      Garcia's Auto Detailing is your trusted partner for motorcycle detailing services. We understand the unique needs of motorcycle enthusiasts and take pride in providing specialized care to keep your bikes looking their best.
      </p>
      <h2>Gallery</h2>
      <Slideshow imageUrls={imageUrls} /> {/* Add the Slideshow component here */}

      {/* Include a gallery component here showcasing before-and-after photos */}
      <h2>Our Services</h2>
      <p>
        Our comprehensive motorcycle detailing services include:
      </p>
      <ul>
        <li>
          <strong>Exterior Detailing:</strong> We thoroughly clean and protect the exterior surfaces of your motorcycles, ensuring they maintain their shine and are shielded from the elements.
        </li>
        <br />
        <li>
          <strong>Chrome and Metal Polishing:</strong> We specialize in restoring and polishing chrome and metal parts, leaving your motorcycle's accents looking brilliant.
        </li>
        <br />
        <li>
          <strong>Seat and Leather Care:</strong> We clean and condition motorcycle seats and leather components, ensuring they stay comfortable and crack-free.
        </li>
        <br />
        <li>
          <strong>Detailing Packages:</strong> Choose from our range of packages designed to cater to your motorcycle's unique needs, ensuring it receives the care it deserves.
        </li>
      </ul>

      <h2>Why Choose Us</h2>
      <ul>
        <li>
          <strong>Motorcycle Enthusiasts:</strong> Our team of detailers shares your passion for motorcycles, and we treat your bikes as if they were our own.
        </li>
        <br />
        <li>
          <strong>Quality Results:</strong> We use high-quality products and techniques to achieve exceptional results, ensuring your motorcycles look their best.
        </li>
        <br />
        <li>
          <strong>Convenience:</strong> We offer flexible scheduling and can work on your motorcycles at your location, making it hassle-free for you.
        </li>
        <br />
        <li>
          <strong>Customer Satisfaction:</strong> Your satisfaction is our priority, and we take pride in exceeding your expectations.
        </li>
      </ul>
    </div>
  );
};

export default Motorcycles;
