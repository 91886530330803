import React from 'react';

const Training = () => {
  return (
    <div className="page-container">
      <h1>Garcia's Auto Detailing now offering training</h1>
      <h2 style={{ color: 'green' }}>More info to come</h2>
      <p><strong>Franchising Opportunities Available: </strong>
      <br />
      Interested in becoming a part of our successful team? 
      Contact us to learn more about franchising opportunities!</p>
    </div>
  );
};

export default Training;
