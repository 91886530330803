import React from 'react';
import Slideshow from '../components/Slideshow'; // Import the Slideshow component

// Sample image URLs (replace with your own)
const imageUrls = [
  '/images/cars_trucks/ct1.jpg',
  '/images/cars_trucks/ct2.jpg',
  '/images/cars_trucks/ct3.jpg',
];

const CarsTrucks = () => {
  return (
    <div className="page-container">
      <h1>Cars and Trucks Detailing Services</h1>
      <p>
        At Garcia's Auto Detailing, we take pride in making your beloved cars and trucks shine like new. Our professional detailing services are tailored to enhance both the appearance and longevity of your vehicles. Whether you have a sleek sports car or a rugged pickup truck, our team of experts is here to provide top-notch care.
      </p>
      <h2>Gallery</h2>
      <Slideshow imageUrls={imageUrls} /> {/* Add the Slideshow component here */}

      {/* Include a gallery component here showcasing before-and-after photos */}
      <h2>Our Services</h2>
      <ul>
        <li>
          <strong>Exterior Detailing:</strong> Our exterior detailing services include a thorough wash, waxing, and polishing to remove dirt, grime, and contaminants from your vehicle's surface. We pay attention to every detail, ensuring that your car or truck gleams with a showroom finish.
        </li>
        <br />
        <li>
          <strong>Interior Detailing:</strong> Step into a refreshed and clean interior with our interior detailing. We clean and sanitize seats, carpets, and upholstery, removing stains and odors to give you that "new car" feel.
        </li>
        <br />
        <li>
          <strong>Paint Correction:</strong> Is your vehicle's paint looking dull or marred by scratches and swirl marks? Our paint correction service restores the shine and brilliance of your car's paint, making it look flawless.
        </li>
        <br />
        <li>
          <strong>Wheel and Tire Care:</strong> We don't neglect your wheels and tires. Our detailing experts clean, shine, and protect your wheels, leaving them looking as good as the rest of your vehicle.
        </li>
        <br />
        <li>
          <strong>Glass and Window Treatment:</strong> Crystal clear windows are essential for safe driving. We clean and treat your vehicle's glass to ensure visibility is never compromised.
        </li>
        <br />
        <li>
          <strong>Detailing Packages:</strong> Choose from our various detailing packages, including basic, premium, and deluxe options, to suit your needs and budget.
        </li>
      </ul>

      <h2>Why Choose Us</h2>
      <ul>
        <li>
          <strong>Experienced Detailers:</strong> Our team comprises skilled and experienced detailers who are passionate about making your vehicles look their best.
        </li>
        <br />
        <li>
          <strong>Quality Products:</strong> We use premium-quality detailing products and techniques to ensure your vehicle receives the best care.
        </li>
        <br />
        <li>
          <strong>Convenience:</strong> We offer flexible scheduling and can come to your location, whether it's at home or work, to detail your car or truck.
        </li>
        <br />
        <li>
          <strong>Customer Satisfaction:</strong> Your satisfaction is our priority. We go the extra mile to meet and exceed your expectations.
        </li>
        <br />
        <li>
          Ready to give your car or truck the royal treatment it deserves? Contact us today to schedule an appointment or get a free quote. We look forward to bringing out the beauty of your vehicle.
        </li>
      </ul>
    </div>
  );
};

export default CarsTrucks;
