import React from 'react';
import Slideshow from '../components/Slideshow'; // Import the Slideshow component

// Sample image URLs (replace with your own)
const imageUrls = [
  '/images/boats_outdoors/b1.jpg',
  '/images/boats_outdoors/b2.jpg',
  '/images/boats_outdoors/b3.jpg',
];
const Boats = () => {
  return (
    <div className="page-container">
      <h1>Boats, ATV's, and Recreational Vehicles Detailing Services</h1>
      <p>
      Garcia's Auto Detailing offers exceptional detailing services not only for cars and trucks but also for boats, ATV's, and recreational vehicles. We understand the unique needs of these vehicles and provide specialized care to keep them in top-notch condition.
      </p>
      <h2>Gallery</h2>
      <Slideshow imageUrls={imageUrls} /> {/* Add the Slideshow component here */}

      {/* Include a gallery component here showcasing before-and-after photos */}
      <h2>Our Services</h2>
      <p>
        Our comprehensive detailing services for boats, ATV's, and recreational vehicles include:
      </p>
      <ul>
        <li>
          <strong>Exterior Detailing:</strong> We meticulously clean and protect the exterior surfaces of your boats, ATV's, and recreational vehicles, ensuring they look their best and are shielded from the elements.
        </li>
        <br />
        <li>
          <strong>Interior Detailing:</strong> Our interior detailing services for these vehicles encompass thorough cleaning and sanitization, leaving the interiors fresh and inviting for your next adventure.
        </li>
        <br />
        <li>
          <strong>Specialized Care:</strong> We have the expertise to handle the specific needs of boats, ATV's, and recreational vehicles. From fiberglass restoration on boats to tire cleaning on ATV's, we've got you covered.
        </li>
        <br />
        <li>
          <strong>Detailing Packages:</strong> Choose from a range of packages designed to cater to your unique requirements, ensuring your boats, ATV's, and recreational vehicles receive the care they deserve.
        </li>
      </ul>

      <h2>Why Choose Us</h2>
      <ul>
        <li>
          <strong>Specialized Expertise:</strong> Our team of detailers has specialized knowledge and experience in detailing boats, ATV's, and recreational vehicles.
        </li>
        <br />
        <li>
          <strong>Quality Results:</strong> We use high-quality products and techniques to achieve exceptional results, ensuring your vehicles look their best.
        </li>
        <br />
        <li>
          <strong>Convenience:</strong> We offer flexible scheduling and can work on your vehicles at your location, making it hassle-free for you.
        </li>
        <br />
        <li>
          <strong>Customer Satisfaction:</strong> Your satisfaction is our priority, and we take pride in exceeding your expectations.
        </li>
      </ul>
    </div>
  );
};

export default Boats;
