import React from 'react';
import '../css/Header.css'; // Import your CSS file for styling

const Header = () => {
  return (
    <header className="header-container">
      <img className="header-image" src="/images/main/m1.png" alt="Garcias Auto Detailing" />
    </header>
  );
};

export default Header;
